<template>
  <div class="content">
    <div class="m-card">
      <div>
        <div class="m-whitebox">
          <Row :gutter="8" class="list-border">
            <i-col span="4">e-mail</i-col>
          </Row>
          <div class="account-box">
            <div style="margin-bottom: 1em">
              <Input
                v-model="newphone"
                placeholder="Please enter your e-mail"
                type="text"
                style="width: 290px"
              />
            </div>

            <div class="service-code">
              <Input
                v-model="newcode"
                placeholder="Please enter your code"
                type="text"
                style="width: 290px"
              />
              <Button
                type="text"
                v-if="!newTimer"
                @click="getCode"
                class="code-btn1"
                >send</Button
              >
              <Button
                type="text"
                v-else
                disabled
                class="code-btn2"
                style="color: #ccc"
                >{{ newCount }}s</Button
              >
            </div>
            <div class="button-box">
              <Button @click="send()" type="primary" :loading="loading"
                >Save</Button
              >
              <Button @click="resetInit()">Cancel</Button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "bind",
  data() {
    return {
      loading: false,
      bindPhone: false, // 绑定新手机号码
      phone: "",
      newcode: "", // 验证码
      msgStr: "发送验证码",
      onOff: true,
      newphone: "",
      link: "",
      openMsg: true,

      newCount: 0,
      timer: null,
      newTimer: null,
      codeKey:''
    };
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      uploadUrl: "getUploadUrl",
      formHeaders: "getFormHeaders",
      userInfo: "getUser",
    }),
  },
  created() {
    if (!(this.userInfo && this.userInfo.phoneConfirm)) {
      this.bindPhone = true;
    }
  },
  methods: {
    ...mapActions({
      validatePhone: "loginModule/validatePhone",
      getMsgCode: "getMsgCode",
      validateCode: "loginModule/validateCode",
      patchUser: "user/patchUser",

      sendEamilCode:'en/sendEamilCode',
      putEmail:'en/putEmail',
    }),
    // async next() {
    //   const bool = await this.validMsgCode();
    //   console.log("bool:", bool);
    //   if (bool) {
    //     this.reset();
    //     this.bindPhone = true;
    //   }
    // },
    resetInit() {
      this.$router.push(
        `/user/password/user?exhibitionId=${this.exhibitionId}`
      );
    },

    // 邮箱验证
    validatorEamil(val) {
      return /^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/.test(val);
    },
    // 获取手机号验证码
    async getCode(){
      console.log(this.newphone,'123213')
      if (!this.validatorEamil(this.newphone)) {
        this.$Message.error('error Eamil')
        return;
      } else {
        this.newDownTime();
        let res = await this.sendEamilCode({
          email: this.newphone
        });
        if (res.result) {
          this.$Message.success("send success");
          this.codeKey = res.data.key;
        } else {
          
          this.$Message.error('fail')
        }
      }
    },
    //验证手机号
    async Checkphone() {
      let res = await this.validateCode({
        key: this.codeKey,
        code: this.newcode
      });
      return new Promise((resolve, reject) => {
        if (res == true) {
          this.resetDown();
          resolve(true);
        } else {
          this.$Message.error('Validation failed')
          this.resetDown();
          resolve(false);
        }
      })
    },


    resetDown(){ // 重置验证码
      clearInterval(this.newTimer);
      this.newCount = 0;
      this.newTimer = null;
      this.newcode = "";
    },

    //新手机号倒计时
    newDownTime() {
      const newTIME_COUNT = 60;
      if (!this.newTimer) {
        this.newCount = newTIME_COUNT;
        const newTimer = setInterval(() => {
          let count = this.newCount;
          if (count > 0 && count <= newTIME_COUNT) {
            count--;
            this.newCount = count;
          } else {
            clearInterval(this.newTimer);
            this.newTimer = null;
          }
        }, 1000);
        this.newTimer = newTimer;
      }
    },

    // 修改手机
    async send() {
      if (!this.validatorEamil(this.newphone)) {
        this.$Message.error('The email format is incorrect')
        return;
      }
      if (!this.newcode) {
        this.$Message.error('Please enter the verification code')
        return;
      }
      let res1  = await this.Checkphone();
      if(res1){
        let MobBody = {
            memberId:this.userInfo.id,
            email:this.newphone,
        }
        let data = await this.putEmail(MobBody);
        console.log(data,'data')
        if(data.result){
            this.$Message.success('success');
            this.resetInit();
        } else {
          if(data.message=='邮箱已存在'){
            this.$Message.error('Email already exists')
          }
          else if(data.message=='MemberId无效'){
            this.$Message.error('MemberId invalid')
          }
          else if(data.mesage=='Email不是邮箱'){
            this.$Message.error("The email format is incorrect")
          }
          else{
            this.$Message.error('fail');
          }
            
        }
      }
    },




    // async getCaptcha2() {
    //   if (!this.newPhone) {
    //     let txt = "";
    //     if (!txt) {
    //       txt = "Please enter the email";
    //     } else if (/^[1][0-9]{10}$/.test(this.newPhone)) {
    //       txt = "";
    //     } else {
    //       txt = "请输入正确的手机号码";
    //     }
    //     if (txt) {
    //       this.$Message.error({ content: txt, duration: 2 });
    //     }
    //     return;
    //   }
    //   let res = await this.validatePhone(this.newPhone);
    //   if (res && res.data) {
    //     this.$Message.error({
    //       content: `手机号（${this.newPhone}）已绑定其他账号`,
    //       duration: 2,
    //     });
    //     return;
    //   } else {
    //     this.downTime2();
    //   }
    // },
    // async getCaptcha() {
    //   this.downTime();
    // },
    // async downTime2() {
    //   const valid = await this.getMsgCode(this.newPhone);
    //   if (valid && valid.result) {
    //     this.codeKey = valid.data.key;
    //   }
    //   const TIME_COUNT = 60;
    //   if (!this.timer) {
    //     this.count = TIME_COUNT;
    //     this.showCode = false;
    //     this.timer = setInterval(() => {
    //       if (this.count > 0 && this.count <= TIME_COUNT) {
    //         this.count--;
    //       } else {
    //         this.showCode = true;
    //         clearInterval(this.timer);
    //         this.timer = null;
    //       }
    //     }, 1000);
    //   }
    // },
    // async downTime() {
    //   const valid = await this.getMsgCode(this.phone);
    //   if (valid && valid.result) {
    //     this.codeKey = valid.data.key;
    //   }
    //   const TIME_COUNT = 60;
    //   if (!this.timer) {
    //     this.count = TIME_COUNT;
    //     this.showCode = false;
    //     this.timer = setInterval(() => {
    //       if (this.count > 0 && this.count <= TIME_COUNT) {
    //         this.count--;
    //       } else {
    //         this.showCode = true;
    //         clearInterval(this.timer);
    //         this.timer = null;
    //       }
    //     }, 1000);
    //   }
    // },
    // async validMsgCode() {
    //   // 验证code
    //   if (!this.numCode) return false;
    //   if (!this.codeKey) {
    //     this.$Message.warning("Get verification code!");
    //     return false;
    //   }
    //   this.loading = true;
    //   setTimeout(() => {
    //     this.loading = false;
    //   }, 5000);

    //   let valid = await this.validateCode({
    //     key: this.codeKey,
    //     code: this.numCode,
    //   });
    //   if (!valid) {
    //     this.$Message.error("验证未通过!");
    //     return false;
    //   }
    //   return true;
    // },
    // async send() {
    //   // 验证code
    //   if (!this.numCode) return false;
    //   if (!this.codeKey) {
    //     this.$Message.warning("Get verification code!");
    //     return false;
    //   }
    //   let valid = await this.validateCode({
    //     key: this.codeKey,
    //     code: this.numCode,
    //   });
    //   if (!valid) {
    //     this.$Message.error("验证未通过!");
    //     return false;
    //   }

    //   this.loading = true;
    //   setTimeout(() => {
    //     this.loading = false;
    //   }, 5000);
    //   const req = {
    //     id: this.userInfo.inMember.memberId,
    //     phone: this.newPhone,
    //   };
    //   console.log(req,'req')
    //   // let bool = await this.patchUser(req).catch((err) => {
    //   //   this.loading = false;
    //   // });
    //   // if (bool.result) {
    //   //   this.$Message.success("绑定成功！");
    //   //   this.$router.push(
    //   //     `/user/password/user?exhibitionId=${this.exhibitionId}`
    //   //   );
    //   // } else {
    //   //   this.$Message.error(bool.message);
    //   // }
    //   // this.loading = false;
    // },

    // reset() {
    //   this.msgStr = "发送验证码";
    //   this.onOff = !this.onOff;
    //   this.bindPhone = false;
    //   this.loading = false;
    //   this.showCode = true;
    //   this.phone = "";
    //   this.numCode = "";
    //   this.onOff = true;
    //   this.newPhone = "";
    //   this.link = "";
    //   this.openMsg = true;
    //   this.count = "";
    //   this.codeKey = "";
    //   clearInterval(this.timer);
    //   this.timer = null;
    // },
  },
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
.content {
  min-height: 700px;
  .m-card {
    border: solid 1px #ededed;

    .mc-top-title::before {
      opacity: 0;
      font-size: 16px;
    }
    .m-whitebox {
      min-width: 900px;
      background: #fff;
      min-height: 500px;
      margin: 24px;
    }
    .list-border {
      color: #999;
      line-height: 40px;
      border-bottom: 1px solid rgb(221, 221, 221);
      padding: 10px 0;
    }
    .account-box {
      padding-top: 20px;
      padding-left: 60px;
      width: 330px;
      & > p {
        margin-bottom: 1em;
        text-indent: 4px;
      }
    }
    .button-box {
      margin-top: 20px;
      button {
        width: 74px;
        margin-right: 5px;
      }
    }
    .service-code {
      position: relative;
      .code-btn1 {
        position: absolute;
        top: 2px;
        right: -10px;
        height: 28px;
        padding: 0;
        @include font_color(#1890ff);
      }
      .code-btn2 {
        position: absolute;
        top: 2px;
        right: -10px;
        height: 28px;
        padding: 0;
      }
    }
  }
}
</style>
